import axios from 'axios';
import { getApiUrl } from 'helpers';

export default async function updateAppointmentInfo(changelog) {
    const url = getApiUrl('/appts/update-info');
    console.log('url that is being called is', url);
    const response = await axios.post(url, {
        changelog,
    });
    const { data } = response;
    console.log('\n\ndata returned from the api call is\n\n', data);
    return data;
}
