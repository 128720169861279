export const showUntil = new Date('2024-12-31');
export const UPDATE_NOTIFICATION_VERSION = 'update_notification_345'; // also update this in the useEffect on app.js to make sure to set it if the user does actually refresh
export const devChanges = [
    `Made several updates to improve the user experience. 12/9/2024`,
    // `You can edit the client's email, phone number and/or birthday by double clicking on any of the fields.`,
    // `You now have the option to press 'Enter' or 'Save' to submit changes.`,
];
export const somethingNew = true;

export const isTesting = true;
