import axios from 'axios';
import { dispatch } from 'store';
import {
    setColor,
    setTimeZone,
    setDibsStudioId,
    setStudioName,
    setEntryPage,
    setCountry,
    setCurrencyCode,
} from 'store/slices/dibsstudio';

export default async function updateStudioConfig(dibsId) {
    console.log('\n\n\nUPDATE STUDIO CONFIG');
    const updateBasicConfigs = async () => {
        const baseURL = process.env.REACT_APP_BASE_URL;
        const basicurl = `${baseURL}/widget/get-basic-config`;
        await axios
            .post(basicurl, { dibsStudioId: dibsId })
            .then((response) => {
                dispatch(setDibsStudioId(dibsId));
                dispatch(setColor(response.data.color));
                dispatch(setTimeZone(response.data.timezone));
                dispatch(setStudioName(response.data.studioName));
                dispatch(setEntryPage(response.data.widgetEntryPoint));
                dispatch(setCountry(response.data.country));
                dispatch(setCurrencyCode(response.data.currency));
            })
            .catch((error) => {
                console.log(error);
            });
    };

    updateBasicConfigs();
}
