import PropTypes from 'prop-types';
import * as React from 'react';
import { useTheme, styled } from '@mui/material/styles';
// material-ui
import {
    Box,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableSortLabel,
    TableRow,
    Switch,
    Collapse,
    Stack,
    Typography,
    Grid,
    Button,
} from '@mui/material';
import { red, grey, green } from '@mui/material/colors';
import { visuallyHidden } from '@mui/utils';
import ConfirmationModal from 'components/modal/ConfirmationModal';
import Chip from 'ui-component/extended/ChipNew';
import DataTableEditableField from './DataTableEditingField';
import ArrayOfChips from 'ui-component/pickers/ArrayOfChips';
import setAlert from 'actions/status/setAlert';
import translate from 'helpers/translate';
import LinearProgress from 'assets/LinearProgress';

const GreenSwitch = styled(Switch)(() => ({
    '& .MuiSwitch-switchBase': {
        color: grey[400],
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
        color: green[200],
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
        backgroundColor: green[200],
    },
    '& .MuiSwitch-switchBase + .MuiSwitch-track': {
        backgroundColor: grey[400],
    },
}));
// table filter

function descendingComparator(a, b, orderBy, orderByValue) {
    if (b[orderByValue] < a[orderByValue]) {
        return -1;
    }
    if (b[orderByValue] > a[orderByValue]) {
        return 1;
    }
    return 0;
}
const getComparator = (order, orderBy, orderByValue) =>
    order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy, orderByValue)
        : (a, b) => -descendingComparator(a, b, orderBy, orderByValue);

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });

    return stabilizedThis.map((el) => el[0]);
}
// ==============================|| TABLE - HEADER ||============================== //

// function EnhancedTableHead({ order, orderBy, numSelected, rowCount, onRequestSort }) {
function EnhancedTableHead({ order, orderBy, onRequestSort, headers }) {
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };
    const theme = useTheme();
    const activatedT = translate({
        id: 'activated',
        msg: 'Activated',
    });
    return (
        <TableHead>
            <TableRow sx={{ backgroundColor: theme.palette.grey[100] }}>
                {headers.map((headCell) => {
                    const labelhere = translate({
                        id: headCell.translationid,
                        msg: headCell.label,
                    });
                    return (
                        <TableCell
                            key={headCell.id}
                            align={headCell.alignment}
                            padding={
                                headCell.disablePadding ? 'none' : 'normal'
                            }
                            sortDirection={
                                orderBy === headCell.id ? order : false
                            }
                            sx={{ pl: 3 }}
                        >
                            {headCell.disableSort ? (
                                <span>{labelhere}</span>
                            ) : (
                                <TableSortLabel
                                    active={orderBy === headCell.id}
                                    direction={
                                        orderBy === headCell.id ? order : 'asc'
                                    }
                                    onClick={createSortHandler(headCell.id)}
                                >
                                    {labelhere}
                                    {orderBy === headCell.id ? (
                                        <Box
                                            component="span"
                                            sx={visuallyHidden}
                                        >
                                            {order === 'desc'
                                                ? 'sorted descending'
                                                : 'sorted ascending'}
                                        </Box>
                                    ) : null}
                                </TableSortLabel>
                            )}
                        </TableCell>
                    );
                })}
                <TableCell sortDirection={false} align="center" sx={{ pr: 3 }}>
                    {activatedT}
                </TableCell>
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    // numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    // rowCount: PropTypes.number.isRequired
    headers: PropTypes.array.isRequired,
};

// ==============================|| TABLE - DATA TABLE NEW - CONFIGURED FOR ALL DATA ||============================== //

export default function EnhancedDataTable({
    tabletype,
    setRefreshData,
    columnstoshow,
    datarows,
    headercellsdata,
    deactivateAction,
    secondaryValues,
    chipOptions,
    saveAction,
    numRowsToShow,
}) {
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('1');
    const [page, setPage] = React.useState(0);
    const colspan = headercellsdata?.length + 1 || 2;
    const [counterEdits, setCounterEdits] = React.useState(0);
    // const [headers, setHeaders] = React.useState([]);

    const [rows, setRows] = React.useState(datarows);
    const [rowsPerPage, setRowsPerPage] = React.useState(numRowsToShow);
    const [isEditingRowId, setIsEditingRowId] = React.useState(null);
    const [rowBeingSaved, setRowBeingSaved] = React.useState(null);
    const [isEditing, setIsEditing] = React.useState(false);
    const [didConfirm, setDidConfirm] = React.useState(false);
    const [expandedRows, setExpandedRows] = React.useState([]);
    const [isEditingFields, setIsEditingFields] = React.useState(false);
    const [editingForRow, setEditingForRow] = React.useState(null);
    const [confirmationQuestion, setConfirmationQuestion] =
        React.useState(null);
    const [isEditingChips, setIsEditingChips] = React.useState(false);
    const [changeLog, setChangeLog] = React.useState([]);
    React.useEffect(() => {
        if (datarows) setRows(datarows);
    }, [datarows]);
    React.useEffect(() => {
        if (didConfirm) {
            // take the action
            deactivateAction(isEditingRowId).then(() => {
                setDidConfirm(false);
                setConfirmationQuestion(null);
                setIsEditingRowId(null);
                setIsEditing(false);
                setRefreshData(true);
            });
        }
    }, [deactivateAction, didConfirm, isEditingRowId, setRefreshData]);
    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };
    const addtoChangeLog = (newChange) => {
        const tempChangeLog = changeLog.filter((change) => {
            return change.searchid !== newChange.searchid;
        });
        setChangeLog([...tempChangeLog, newChange]);
    };
    const handleEditSaveClick = async (id) => {
        // if already editing - so this should be a save
        if (editingForRow === id) {
            setRowBeingSaved(id);
            console.log('\n\n\nchange log here is', changeLog);
            await saveAction(changeLog).then((res) => {
                console.log('response after saving changes', res);
                const { error } = res;
                if (error) {
                    setAlert({
                        msg: `There was an error. ${error} Please refresh your screen and try again.`,
                        seconds: 7,
                        error: true,
                    });
                } else {
                    setAlert({
                        msg: `Success!`,
                        seconds: 2,
                        success: true,
                    });
                }
                setRowBeingSaved(null);
            });
            const newExpandedRows = expandedRows.filter(
                (rowid) => rowid !== id
            );
            setExpandedRows(newExpandedRows);
            setIsEditingFields(false);
            setEditingForRow(null);
            setChangeLog([]);
            setCounterEdits(counterEdits + 1);
        } else {
            setIsEditingFields(true);
            setEditingForRow(id);
            setIsEditingChips(false);
        }
    };
    const handleCancelClick = (id) => {
        // is already editing - reset everything
        if (editingForRow === id) {
            const newExpandedRows = expandedRows.filter(
                (rowid) => rowid !== id
            );
            setExpandedRows(newExpandedRows);
            setIsEditingFields(false);
            setEditingForRow(null);
            setChangeLog([]);
            setCounterEdits(counterEdits + 1);
        }
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleDeactivate = (e, id, name) => {
        setIsEditingRowId(id);
        setConfirmationQuestion(
            `Are you sure you want to deactivate the '${name}' ${tabletype}?`
        );
        setIsEditing(true);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event?.target.value, 10));
        setPage(0);
    };
    const handleClickOnRow = (e, id, rowIsExpanded, isEditingTHISRow) => {
        if (!rowIsExpanded) {
            setExpandedRows([...expandedRows, id]);
        } else {
            if (isEditingTHISRow) {
                return;
            }
            const newExpandedRows = expandedRows.filter(
                (rowid) => rowid !== id
            );
            setExpandedRows(newExpandedRows);
            setIsEditingChips(false);
        }
    };
    const handleClickOnChipColumn = (
        e,
        id,
        rowIsExpanded,
        isEditingTHISRow
    ) => {
        if (!rowIsExpanded) {
            setExpandedRows([...expandedRows, id]);
        } else {
            if (isEditingTHISRow) {
                setIsEditingChips(true);
                return;
            }
            const newExpandedRows = expandedRows.filter(
                (rowid) => rowid !== id
            );
            setExpandedRows(newExpandedRows);
            setIsEditingChips(false);
        }
    };
    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows?.length) : 0;
    const indextoorder = orderBy - 1;
    const orderbyValue = columnstoshow[indextoorder];
    const cancelDeactivationAction = () => {
        setIsEditingRowId(null);
        setIsEditing(false);
        setConfirmationQuestion(null);
    };
    const rowsperpageLabel = translate({
        id: 'rowsperpagelabel',
        msg: 'Rows per page',
    });
    return (
        <Paper sx={{ width: '95%', mb: 2 }}>
            <ConfirmationModal
                openStatus={isEditing}
                confirmationQuestion={confirmationQuestion}
                setDidConfirm={setDidConfirm}
                cancelAction={cancelDeactivationAction}
            />
            {/* table */}
            <TableContainer>
                <Table sx={{ minWidth: 750 }}>
                    <EnhancedTableHead
                        order={order}
                        headers={headercellsdata}
                        orderBy={orderBy}
                        onRequestSort={handleRequestSort}
                        rowCount={rows?.length}
                    />
                    <TableBody>
                        {stableSort(
                            rows,
                            getComparator(order, orderBy, orderbyValue)
                        )
                            .slice(
                                page * rowsPerPage,
                                page * rowsPerPage + rowsPerPage
                            )
                            .map((row, index) => {
                                /** Make sure no display bugs if row isn't an object */

                                if (typeof row === 'number') return null;
                                const labelId = `enhanced-table-new-${index}-${row.id}`;
                                const rowid = row.id;

                                let rowIsExpanded = false;
                                let isEditingThisRow = false;
                                if (expandedRows.includes(rowid)) {
                                    rowIsExpanded = true;
                                    if (editingForRow === rowid) {
                                        isEditingThisRow = true;
                                    } else {
                                        isEditingThisRow = false;
                                    }
                                }
                                let thisRowIsBeingSaved = false;
                                if (rowBeingSaved === rowid) {
                                    thisRowIsBeingSaved = true;
                                }
                                if (thisRowIsBeingSaved) {
                                    return (
                                        <React.Fragment key={rowid}>
                                            <TableRow>
                                                <TableCell
                                                    sx={{
                                                        py: '20px',
                                                        px: '10%',
                                                    }}
                                                    colSpan={colspan}
                                                    align="center"
                                                >
                                                    <LinearProgress />
                                                </TableCell>
                                            </TableRow>
                                        </React.Fragment>
                                    );
                                }

                                const firstColumnTitle = columnstoshow[0];

                                const bgcolorhover = rowIsExpanded
                                    ? 'white !important'
                                    : 'inherit';
                                let indexOfDollarAmount = -1;

                                return (
                                    <React.Fragment key={rowid}>
                                        <TableRow
                                            hover
                                            tabIndex={-1}
                                            key={rowid}
                                            sx={{
                                                '&:hover': {
                                                    backgroundColor:
                                                        bgcolorhover,
                                                },
                                            }}
                                        >
                                            <TableCell
                                                key={`column-${index}-${labelId}`}
                                                component="th"
                                                id={labelId}
                                                scope="row"
                                                padding="none"
                                                sx={{
                                                    pl: 3,
                                                    borderBottom: rowIsExpanded
                                                        ? 0
                                                        : 1,
                                                    borderTop: rowIsExpanded
                                                        ? 1
                                                        : 0,
                                                    borderColor: rowIsExpanded
                                                        ? 'grey.400'
                                                        : 'grey.200',
                                                    cursor: 'pointer',
                                                    color: rowIsExpanded
                                                        ? '#c96248'
                                                        : 'inherit',
                                                    fontSize: rowIsExpanded
                                                        ? '0.9rem'
                                                        : '0.775rem',
                                                    fontWeight: rowIsExpanded
                                                        ? 600
                                                        : 400,
                                                }}
                                                onClick={(e) => {
                                                    handleClickOnRow(
                                                        e,
                                                        rowid,
                                                        rowIsExpanded,
                                                        isEditingThisRow
                                                    );
                                                }}
                                            >
                                                <DataTableEditableField
                                                    id={rowid}
                                                    key={`editable-field-${rowid}-${firstColumnTitle}-${counterEdits}`}
                                                    label={
                                                        row[firstColumnTitle]
                                                    }
                                                    isEditing={isEditingThisRow}
                                                    typefield={`text`}
                                                    optionsfield={null}
                                                    specialfontsize={'0.9rem'}
                                                    specialfontWeight={600}
                                                    specialfontcolor="#c96248"
                                                    lineMultiplier={8.8}
                                                    addToChangeLog={
                                                        addtoChangeLog
                                                    }
                                                    fieldEditing={
                                                        firstColumnTitle
                                                    }
                                                    counter={counterEdits}
                                                />
                                                {/* {row[firstColumnTitle]} */}
                                            </TableCell>
                                            {columnstoshow.map(
                                                (column, index) => {
                                                    if (
                                                        column ===
                                                        'default_price'
                                                    ) {
                                                        indexOfDollarAmount =
                                                            index;
                                                    }
                                                    if (index === 0)
                                                        return null;

                                                    if (
                                                        column === 'chipcolumn'
                                                    ) {
                                                        const chips =
                                                            row.chipsToShow;
                                                        if (
                                                            isEditingChips &&
                                                            isEditingThisRow
                                                        ) {
                                                            return (
                                                                <TableCell
                                                                    id={`chip-cell-chip-array-${labelId}-${index}`}
                                                                    key={`chip-array-${labelId}-${index}`}
                                                                    sx={{
                                                                        borderBottom: 0,
                                                                        cursor: 'pointer',
                                                                        borderTop:
                                                                            rowIsExpanded
                                                                                ? 1
                                                                                : 0,
                                                                        borderColor:
                                                                            rowIsExpanded
                                                                                ? 'grey.400'
                                                                                : 'grey.200',
                                                                    }}
                                                                >
                                                                    <ArrayOfChips
                                                                        thisrowid={
                                                                            rowid
                                                                        }
                                                                        chipOptions={
                                                                            chipOptions
                                                                        }
                                                                        chipsSelected={
                                                                            chips
                                                                        }
                                                                        addToChangeLog={
                                                                            addtoChangeLog
                                                                        }
                                                                        counter={
                                                                            counterEdits
                                                                        }
                                                                    />
                                                                </TableCell>
                                                            );
                                                        }

                                                        return (
                                                            <TableCell
                                                                align="center"
                                                                id={`chip-cell-${labelId}-${index}`}
                                                                key={`chip-${labelId}-${index}`}
                                                                onClick={(
                                                                    e
                                                                ) => {
                                                                    handleClickOnChipColumn(
                                                                        e,
                                                                        rowid,
                                                                        rowIsExpanded,
                                                                        isEditingThisRow
                                                                    );
                                                                }}
                                                                sx={{
                                                                    borderBottom:
                                                                        rowIsExpanded
                                                                            ? 0
                                                                            : 1,

                                                                    borderTop:
                                                                        rowIsExpanded
                                                                            ? 1
                                                                            : 0,
                                                                    borderColor:
                                                                        rowIsExpanded
                                                                            ? 'grey.400'
                                                                            : 'grey.200',
                                                                    cursor: 'pointer',
                                                                }}
                                                            >
                                                                {chips.map(
                                                                    (
                                                                        chiplabel,
                                                                        j
                                                                    ) => {
                                                                        return (
                                                                            <Chip
                                                                                key={`chip-${labelId}-${index}-${j}`}
                                                                                label={
                                                                                    chiplabel
                                                                                }
                                                                                onClick={(
                                                                                    e
                                                                                ) => {
                                                                                    console.log(
                                                                                        'clicked chip'
                                                                                    );
                                                                                }}
                                                                                chipType={
                                                                                    chiplabel
                                                                                }
                                                                                size="small"
                                                                                color="primary"
                                                                                sx={{
                                                                                    m: 0.5,
                                                                                }}
                                                                            />
                                                                        );
                                                                    }
                                                                )}
                                                            </TableCell>
                                                        );
                                                    }

                                                    return (
                                                        <TableCell
                                                            key={`column-${index}`}
                                                            align="left"
                                                            sx={{
                                                                pl: 3.5,
                                                                borderBottom:
                                                                    rowIsExpanded
                                                                        ? 0
                                                                        : 1,
                                                                borderTop:
                                                                    rowIsExpanded
                                                                        ? 1
                                                                        : 0,
                                                                borderColor:
                                                                    rowIsExpanded
                                                                        ? 'grey.400'
                                                                        : 'grey.200',

                                                                cursor: 'pointer',
                                                                fontSize:
                                                                    rowIsExpanded
                                                                        ? '0.875rem'
                                                                        : '0.775rem',
                                                                fontWeight:
                                                                    rowIsExpanded
                                                                        ? 600
                                                                        : 400,
                                                            }}
                                                            onClick={(e) => {
                                                                handleClickOnRow(
                                                                    e,
                                                                    rowid,
                                                                    rowIsExpanded,
                                                                    isEditingThisRow
                                                                );
                                                            }}
                                                        >
                                                            {/* {row[column]} */}
                                                            <DataTableEditableField
                                                                id={rowid}
                                                                label={
                                                                    row[column]
                                                                }
                                                                isMoney={
                                                                    index ===
                                                                    indexOfDollarAmount
                                                                }
                                                                isEditing={
                                                                    isEditingThisRow
                                                                }
                                                                typefield={`text`}
                                                                optionsfield={
                                                                    null
                                                                }
                                                                specialfontsize={
                                                                    '0.875rem'
                                                                }
                                                                specialfontWeight={
                                                                    600
                                                                }
                                                                lineMultiplier={
                                                                    10
                                                                }
                                                                fieldEditing={
                                                                    column
                                                                }
                                                                counter={
                                                                    counterEdits
                                                                }
                                                                addToChangeLog={
                                                                    addtoChangeLog
                                                                }
                                                                needsmorespace={
                                                                    row[column]
                                                                        .needsmorespace
                                                                }
                                                                specifiedwidth={
                                                                    row[column]
                                                                        .specifiedwidth
                                                                }
                                                            />
                                                        </TableCell>
                                                    );
                                                }
                                            )}
                                            <TableCell
                                                align="center"
                                                sx={{
                                                    pr: 1,
                                                    borderBottom: rowIsExpanded
                                                        ? 0
                                                        : 1,
                                                    borderTop: rowIsExpanded
                                                        ? 1
                                                        : 0,
                                                    borderColor: rowIsExpanded
                                                        ? 'grey.400'
                                                        : 'grey.200',
                                                    cursor: 'pointer',
                                                }}
                                            >
                                                <Box
                                                    sx={{
                                                        justifyContent:
                                                            'center',
                                                    }}
                                                >
                                                    <GreenSwitch
                                                        id={`${row['id']}-switch`}
                                                        checked={
                                                            isEditingRowId !==
                                                            row['id']
                                                        }
                                                        onChange={(e) =>
                                                            handleDeactivate(
                                                                e,
                                                                row['id'],
                                                                row[
                                                                    'appointment_type'
                                                                ]
                                                            )
                                                        }
                                                        sx={{
                                                            align: 'center',
                                                            mr: 1,
                                                        }}
                                                    />
                                                </Box>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell
                                                style={{
                                                    paddingBottom: 0,
                                                    paddingTop: 0,
                                                }}
                                                sx={{
                                                    borderBottom: rowIsExpanded
                                                        ? 1.5
                                                        : 0,
                                                    borderColor: 'grey.400',
                                                    cursor: 'pointer',
                                                }}
                                                colSpan={colspan}
                                            >
                                                <Collapse
                                                    in={rowIsExpanded}
                                                    timeout="auto"
                                                    unmountOnExit
                                                >
                                                    <Grid
                                                        container
                                                        justifyContent="space-evenly"
                                                    >
                                                        <Grid
                                                            item
                                                            xs
                                                            onClick={(e) => {
                                                                handleClickOnRow(
                                                                    e,
                                                                    rowid,
                                                                    rowIsExpanded,
                                                                    isEditingThisRow
                                                                );
                                                            }}
                                                        >
                                                            <Box
                                                                sx={{
                                                                    margin: 1,
                                                                    padding: 1,
                                                                    paddingTop: 3,
                                                                    border: 1,
                                                                    borderRadius: 2,
                                                                    borderColor:
                                                                        'grey.300',
                                                                    bgcolor:
                                                                        '#fafafa',
                                                                    marginBottom: 1,
                                                                }}
                                                            >
                                                                <Table>
                                                                    <TableBody>
                                                                        <TableRow>
                                                                            {secondaryValues.map(
                                                                                (
                                                                                    nextValue
                                                                                ) => {
                                                                                    const valuetoshow =
                                                                                        row[
                                                                                            nextValue
                                                                                                .value
                                                                                        ];
                                                                                    let formattedvalue =
                                                                                        valuetoshow;

                                                                                    // replace formatted value with the mapped value if needed

                                                                                    const typeSelect =
                                                                                        nextValue.typeSelect;
                                                                                    const optionsSelect =
                                                                                        nextValue.options;
                                                                                    /* {
                                                                                        /* formattedvalue =
                                                                                        switchAutopayOptions(
                                                                                            formattedvalue,
                                                                                            'toDisplay'
                                                                                        ); */

                                                                                    return (
                                                                                        <TableCell
                                                                                            key={`secondary-${nextValue.value}`}
                                                                                            sx={{
                                                                                                borderBottom: 0,
                                                                                                paddingTop: 0,
                                                                                            }}
                                                                                        >
                                                                                            <Stack>
                                                                                                <Grid>
                                                                                                    <Typography variant="h8underline">
                                                                                                        {
                                                                                                            nextValue.label
                                                                                                        }
                                                                                                    </Typography>
                                                                                                </Grid>
                                                                                                <Grid>
                                                                                                    <DataTableEditableField
                                                                                                        id={
                                                                                                            rowid
                                                                                                        }
                                                                                                        label={
                                                                                                            formattedvalue
                                                                                                        }
                                                                                                        isEditing={
                                                                                                            isEditingThisRow
                                                                                                        }
                                                                                                        typefield={
                                                                                                            typeSelect
                                                                                                        }
                                                                                                        optionsfield={
                                                                                                            optionsSelect
                                                                                                        }
                                                                                                        fieldEditing={
                                                                                                            nextValue.value
                                                                                                        }
                                                                                                        addToChangeLog={
                                                                                                            addtoChangeLog
                                                                                                        }
                                                                                                        counter={
                                                                                                            counterEdits
                                                                                                        }
                                                                                                        needsmorespace={
                                                                                                            nextValue.needsmorespace
                                                                                                        }
                                                                                                        specifiedwidth={
                                                                                                            nextValue.specifiedwidth
                                                                                                        }
                                                                                                    />
                                                                                                </Grid>
                                                                                            </Stack>
                                                                                        </TableCell>
                                                                                    );
                                                                                }
                                                                            )}
                                                                        </TableRow>
                                                                    </TableBody>
                                                                </Table>
                                                            </Box>
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            xs={12}
                                                            sx={{ mt: 0 }}
                                                        >
                                                            <Grid
                                                                container
                                                                justifyContent="flex-start"
                                                            >
                                                                {isEditingThisRow && (
                                                                    <Grid item>
                                                                        <Button
                                                                            id="edit-button-data-table"
                                                                            onClick={(
                                                                                e
                                                                            ) =>
                                                                                handleCancelClick(
                                                                                    rowid
                                                                                )
                                                                            }
                                                                            variant="cancel"
                                                                            sx={{
                                                                                mb: 2,
                                                                                mt: 1.2,
                                                                                ml: 2,
                                                                            }}
                                                                        >
                                                                            Cancel
                                                                        </Button>
                                                                    </Grid>
                                                                )}
                                                                <Grid
                                                                    item
                                                                    sx={{
                                                                        mb: 2,
                                                                        mr: 2,
                                                                        mt: 1.2,
                                                                        ml: 2,
                                                                    }}
                                                                >
                                                                    <Button
                                                                        id="edit-button-data-table-line-913"
                                                                        onClick={(
                                                                            e
                                                                        ) =>
                                                                            handleEditSaveClick(
                                                                                rowid
                                                                            )
                                                                        }
                                                                        variant={
                                                                            isEditingThisRow
                                                                                ? 'greenconfirm'
                                                                                : 'edit'
                                                                        }
                                                                    >
                                                                        {isEditingThisRow
                                                                            ? 'Save'
                                                                            : 'Edit'}
                                                                    </Button>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Collapse>
                                            </TableCell>
                                        </TableRow>
                                    </React.Fragment>
                                );
                            })}
                        {emptyRows > 0 && (
                            <TableRow
                                style={{
                                    height: 1 * emptyRows,
                                }}
                            >
                                <TableCell
                                    colSpan={headercellsdata?.length + 1}
                                />
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            {/* table data */}
            <TablePagination
                rowsPerPageOptions={[5, 10, 20, 30, 40]}
                component="div"
                count={rows?.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelRowsPerPage={rowsperpageLabel}
            />
        </Paper>
    );
}
EnhancedDataTable.propTypes = {
    tabletype: PropTypes.string.isRequired,
    setRefreshPromoData: PropTypes.func,
};
